"use client";
import { allHomes, menuItems } from "@/data/menu";
import { toggleMobileMenu } from "@/utlis/toggleMobileMenu";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { init_classic_menu_resize } from "@/utlis/menuToggle";
import { scrollToElement } from "@/utlis/scrollToElement";
export default function HRMSHeader() {
  const [menuOpen, setMenuOpen] = useState([-1, -1]);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHoveredemployee, setIsHoveredemployee] = useState(false);
  const [isHoveredattendance, setIsHoveredattendance] = useState(false);
  const [isHoveredleave, setIsHoveredleave] = useState(false);
  const [isHoveredpayroll, setIsHoveredpayroll] = useState(false);
  const [isHoveredrecruitment, setIsHoveredrecruitment] = useState(false);


  const toggleParent1 = (i) => {
    const tempMenuOpen = [...menuOpen];
    if (menuOpen[0] == i) {
      tempMenuOpen[0] = -1;
    } else {
      tempMenuOpen[0] = i;
    }
    setMenuOpen(tempMenuOpen);
  };
  const toggleParent2 = (i) => {
    const tempMenuOpen = [...menuOpen];
    if (menuOpen[1] == i) {
      tempMenuOpen[1] = -1;
    } else {
      tempMenuOpen[1] = i;
    }
    setMenuOpen(tempMenuOpen);
  };
  const toggleParent3 = (i) => {
    const tempMenuOpen = [...menuOpen];
    if (menuOpen[2] == i) {
      tempMenuOpen[2] = -1;
    } else {
      tempMenuOpen[2] = i;
    }
    setMenuOpen(tempMenuOpen);
  };

  const toggleParent4 = (i) => {
    const tempMenuOpen = [...menuOpen];
    if (menuOpen[3] == i) {
      tempMenuOpen[3] = -1;
    } else {
      tempMenuOpen[3] = i;
    }
    setMenuOpen(tempMenuOpen);
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToElement();
    }, 1000);
    init_classic_menu_resize();
    // window.addEventListener("scroll", addScrollspy);

    window.addEventListener("resize", init_classic_menu_resize);

    return () => {
      // window.removeEventListener("scroll", addScrollspy);
      window.removeEventListener("resize", init_classic_menu_resize);
    };
  }, []);

  const solutionsData = {
    'All Products': {
      title: "Leave Management",
      description: "Manage your inventory and warehouse operations efficiently.",
      image: " ",
      link: "/solutions",
      subItems: [
        {
          title: "Leave Management System",
          link: '/hrms-software/leave-management-system',
          subItems: [
            "Leave Policy & Allocation",
            "Leave History",
            "Leave Policy & Allocation",
            "Leave History",
            "Leave Policy & Allocation",
            "Leave History",
          ]
        },
        {
          title: "Employee Management",
          link: '/hrms-software/Employee-management-system',
          subItems: [
            "Employee View ",
            "Employee Update"
          ]
        },
        {
          title: "Attendance Management",
          link: '/hrms-software/Attendance-management-system',
          subItems: [
            "Attendance Policies",
            "Attendance Adjustment"
          ]
        }
      ]
    },
    'financial': {
      title: "CRM",
      description: "Track, nurture, and convert leads into customers with our comprehensive CRM solution, offering lead scoring, automation, and pipeline management.",
      image: "/assets/images/img/slides/crm_slide.webp",
      link: "/solutions",
      subItems: [
        {
          title: "CRM Management System",
          link: '/hrms-software/CRM-management-system',
          subItems: [
            "CRM Policy & Allocation",
            "CRM History",
            "CRM Policy & Allocation",
            "CRM History",
            "CRM Policy & Allocation",
            "CRM History",
          ]
        },
        {
          title: "Management",
          link: '/hrms-software/Employee-management-system',
          subItems: [
            "Employee View ",
            "Employee Update"
          ]
        },
        {
          title: "Content",
          link: '/hrms-software/Attendance-management-system',
          subItems: [
            "Attendance Policies",
            "Attendance Adjustment"
          ]
        }
      ]
    },
    'vehicle': {
      title: "Campaigns",
      description: "Create and manage email marketing campaigns, track performance, and automate follow-ups with our powerful email marketing tool.",
      image: "/assets/images/img/slides/campaigns_slide.webp",
      link: "/solutions",
      subItems: []
    },
    'inventory': {
      title: "HRMS",
      description: "GetBiz Solutions offers a powerful suite of products, including HRMS and CRM, to streamline your business operations. Enhance productivity and drive growth with our comprehensive tools.",
      image: "/assets/images/img/slides/hrms_slide.webp",
      link: "/solutions",
      subItems: []
    },
    'Leave': {
      title: "Leave Management",
      description: "GetBiz Solutions offers a powerful suite of products, including HRMS and CRM, to streamline your business operations. Enhance productivity and drive growth with our comprehensive tools.",
      image: "/assets/images/img/it_solutions_itsm.webp",
      link: "/solutions",
      subItems: [
        {
          title: "Leave Requests",
          link: '/hrms-software/leave-management-system/leave-requests/',
        },
        {
          title: "Leave Approval",
          link: '/hrms-software/leave-management-system/leave-approval/',
        },
        {
          title: "Leave History",
          link: '/hrms-software/leave-management-system/leave-history',
        },
        {
          title: "Leave Dashboard",
          link: '/hrms-software/leave-management-system/leave-dashboard/',
        },
        {
          title: "Leave Reports",
          link: '/hrms-software/leave-management-system/leave-reports/',
        },
        {
          title: "Leave Balance",
          link: '/hrms-software/leave-management-system/leave-balance',
        },
        {
          title: "Leave Types",
          link: '/hrms-software/leave-management-system/leave-types',
        },
        {
          title: "Leave Policy Allocation",
          link: '/hrms-software/leave-management-system/leave-policy-allocation',
        },
        {
          title: "Leave Accrual Details",
          link: '/hrms-software/leave-management-system/leave-accrual-details',
        },
      ]
    },
    'Attendance': {
      title: "Attendance Management",
      description: "GetBiz Solutions offers a powerful suite of products, including HRMS and CRM, to streamline your business operations. Enhance productivity and drive growth with our comprehensive tools.",
      image: "/assets/images/img/it_solutions_itsm.webp",
      link: "/hrms-software/attendance-management-system/",
      subItems: [
        {
          title: "Real Time Attendance Tracking",
          link: '/hrms-software/attendance-management-system/real-time-attendance-tracking/',
        },
        {
          title: "Absence Management",
          link: '/hrms-software/attendance-management-system/absence-management/',
        },
        {
          title: "Overtime Monitoring",
          link: '/hrms-software/attendance-management-system/overtime-monitoring/',
        },
        {
          title: "Shift Management",
          link: '/hrms-software/attendance-management-system/shift-management/',
        },
        {
          title: "Geofencing Attendance",
          link: '/hrms-software/attendance-management-system/geofencing-attendance/',
        },
        {
          title: "Comprehensive Reporting",
          link: '/hrms-software/attendance-management-system/comprehensive-reporting/',
        },
        {
          title: "Attendance Dashboard",
          link: '/hrms-software/attendance-management-system/attendance-dashboard/',
        }
      ]
    },
    'Employee': {
      title: "Employee Management",
      description: "GetBiz Solutions offers a powerful suite of products, including HRMS and CRM, to streamline your business operations. Enhance productivity and drive growth with our comprehensive tools.",
      image: "/assets/images/img/it_solutions_itsm.webp",
      link: "/hrms-software/employee-management-system/",
      subItems: [
        {
          title: "Employee Directory",
          link: '/hrms-software/employee-management-system/employee-directory/',
        },
        {
          title: "Attendance Tracking",
          link: '/hrms-software/employee-management-system/attendance-tracking/',
        },
        {
          title: "Performance Review",
          link: '/hrms-software/employee-management-system/performance-review/',
        },
        {
          title: "Leave Management",
          link: '/hrms-software/employee-management-system/leave-management/',
        },
        {
          title: "Employee Shift Management",
          link: '/hrms-software/employee-management-system/employee-shift-management/',
        },
        {
          title: "Payroll Integration",
          link: '/hrms-software/employee-management-system/payroll-integration/',
        }
      ]
    },
    'payroll': {
      title: "Employee Management",
      description: "GetBiz Solutions offers a powerful suite of products, including HRMS and CRM, to streamline your business operations. Enhance productivity and drive growth with our comprehensive tools.",
      image: "/assets/images/img/it_solutions_itsm.webp",
      link: "/hrms-software/employee-management-system/",
      subItems: [
        {
          title: "Automated Salary Calculation",
          link: '/hrms-software/payroll-management-system/automated-salary-calculation/',
        },
        {
          title: "Tax and Compliance Management",
          link: '/hrms-software/payroll-management-system/tax-and-compliance-management//',
        },
        {
          title: "Custom Deductions",
          link: '/hrms-software/payroll-management-system/custom-deductions/',
        },
        {
          title: "Overtime and Leave Calculations",
          link: '/hrms-software/payroll-management-system/overtime-leave-calculations/',
        },
        {
          title: "Payslip Generation",
          link: '/hrms-software/payroll-management-system/payslip-generation/',
        },
        {
          title: "Integration with Accounting",
          link: '/hrms-software/payroll-management-system/integration-with-accounting/',
        }
      ]
    },
    'performance': {
      title: "Employee Management",
      description: "GetBiz Solutions offers a powerful suite of products, including HRMS and CRM, to streamline your business operations. Enhance productivity and drive growth with our comprehensive tools.",
      image: "/assets/images/img/it_solutions_itsm.webp",
      link: "/hrms-software/employee-management-system/",
      subItems: [
        {
          title: "Empty",
          link: '/',
        }
      ]
    },
    'recruitment': {
      title: "Employee Management",
      description: "GetBiz Solutions offers a powerful suite of products, including HRMS and CRM, to streamline your business operations. Enhance productivity and drive growth with our comprehensive tools.",
      image: "/assets/images/img/it_solutions_itsm.webp",
      link: "/hrms-software/employee-management-system/",
      subItems: [
        {
          title: "Multi-Platform Job Posting",
          link: '/hrms-software/recruitment-management-systems/multi-platform-job-posting/',
        },
        {
          title: "Resume Parsing",
          link: '/hrms-software/recruitment-management-systems/resume-parsing/',
        },
        {
          title: "Candidate Communication",
          link: '/hrms-software/recruitment-management-systems/candidate-communication/',
        },
        {
          title: "Interview Management",
          link: '/hrms-software/recruitment-management-systems/interview-management/',
        },
        {
          title: "Reporting and Analytics",
          link: '/hrms-software/recruitment-management-systems/reporting-and-analytics/',
        }
      ]
    },
    'traininganddeevlopment': {
      title: "Employee Management",
      description: "GetBiz Solutions offers a powerful suite of products, including HRMS and CRM, to streamline your business operations. Enhance productivity and drive growth with our comprehensive tools.",
      image: "/assets/images/img/it_solutions_itsm.webp",
      link: "/hrms-software/employee-management-system/",
      subItems: [
        {
          title: "Empty",
          link: '/',
        }
      ]
    },
    'employeeengage': {
      title: "Employee Management",
      description: "GetBiz Solutions offers a powerful suite of products, including HRMS and CRM, to streamline your business operations. Enhance productivity and drive growth with our comprehensive tools.",
      image: "/assets/images/img/it_solutions_itsm.webp",
      link: "/hrms-software/employee-management-system/",
      subItems: [
        {
          title: "Empty",
          link: '/',
        }
      ]
    },
    'compliancepolicy': {
      title: "Employee Management",
      description: "GetBiz Solutions offers a powerful suite of products, including HRMS and CRM, to streamline your business operations. Enhance productivity and drive growth with our comprehensive tools.",
      image: "/assets/images/img/it_solutions_itsm.webp",
      link: "/hrms-software/employee-management-system/",
      subItems: [
        {
          title: "Empty",
          link: '/',
        }
      ]
    },
    'analyticsreporting': {
      title: "Employee Management",
      description: "GetBiz Solutions offers a powerful suite of products, including HRMS and CRM, to streamline your business operations. Enhance productivity and drive growth with our comprehensive tools.",
      image: "/assets/images/img/it_solutions_itsm.webp",
      link: "/hrms-software/employee-management-system/",
      subItems: [
        {
          title: "Empty",
          link: '/',
        }
      ]
    },
  };

  const [selectedSolution, setSelectedSolution] = useState(null);
  const [selectedSubItem, setSelectedSubItem] = useState(null);
  const handleMouseOver = (key) => {
    setSelectedSolution(solutionsData[key]);
    setSelectedSubItem(null);
  };
  const handleMouseOverSolution = (key) => {
    setSelectedSolution(solutionsData[key]);
    setSelectedSubItem(null);
  };

  const handleMouseOverSubItem = (subItem) => {
    setSelectedSubItem(subItem);
  };

  useEffect(() => {
    setSelectedSolution(solutionsData['Employee']);
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 430 && window.innerHeight <= 930);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);



  return (
    <div className="main-nav-sub full-wrapper">
      {/* Logo  (* Add your text or image to the link tag. Use SVG or PNG image format. 
              If you use a PNG logo image, the image resolution must be equal 200% of the visible logo
              image size for support of retina screens. See details in the template documentation. *) */}
      <div className="nav-logo-wrap local-scroll">
        <Link href={`/hrms-software`} className="logo">
          <Image
            // src="/assets/images/logo-white.svg"
            // src="/assets/images/img/logo/Getbiz_solutions_hrms.webp"
            src="/assets/images/img/logo/gb_hrms_new.webp"
            alt="Getbiz_solutions_hrms"
            width={205}
            height={34}
          />
        </Link>
      </div>
      {/* Mobile Menu Button */}
      <div
        onClick={toggleMobileMenu}
        className="mobile-nav"
        role="button"
        tabIndex={0}
      >
        <i className="mobile-nav-icon" />
        <span className="visually-hidden">Menu</span>
      </div>
      {/* Main Menu */}
      <div className="inner-nav desktop-nav">
        <ul className="clearlist local-scroll">
          {isMobile ? (
            // Mobile Menu
            <li className={menuOpen[1] === 1 ? "js-opened" : ""}>
              <a href="#" onClick={() => toggleParent2(1)} className="mn-has-sub">
                Features <i className="mi-chevron-down" />
              </a>
              <ul className={`mn-sub mn-has-multi ${menuOpen[1] === 1 ? "mobile-sub-active" : ""}`}>
                <li className="mn-sub-multi" style={{ minWidth: '280px', marginLeft: '-30%', display: 'flex', flexDirection: 'column' }}>
                  <Link href="/hrms-software/leave-management-system/">
                    <span className="mn-group-title">Leave Management</span>
                  </Link>
                  <ul>
                    <li>
                      <Link href="/hrms-software/leave-management-system/leave-policy-allocation">
                        <i className="" />
                        Leave Policy Allocation
                      </Link>
                    </li>
                    <li>
                      <Link href="/hrms-software/leave-management-system/leave-balance">
                        <i className="" />
                        Leave Balance
                      </Link>
                    </li>
                    <li>
                      <Link href="/hrms-software/leave-management-system/leave-accrual-details">
                        <i className="" />
                        Leave Accrual Details
                      </Link>
                    </li>
                    <li>
                      <Link href="/hrms-software/leave-management-system/easy-approval-decline">
                        <i className="" />
                        Easy Approval/Decline
                      </Link>
                    </li>
                    <li>
                      <Link href="/hrms-software/leave-management-system/leave-history">
                        <i className="" />
                        Leave History
                      </Link>
                    </li>
                    <li>
                      <Link href="/hrms-software/leave-management-system/leave-types">
                        <i className="" />
                        Leave Types
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="mn-sub-multi" style={{ minWidth: '280px', marginLeft: '-30%', display: 'flex', flexDirection: 'column' }}>
                  <Link href="/hrms-software/attendance-management/">
                    <span className="mn-group-title">Attendance Management</span>
                  </Link>
                  <ul>
                    <li>
                      <Link href="/">
                        <i className="" />
                        Attendance Policy
                      </Link>
                    </li>
                    <li>
                      <Link href="/">
                        <i className="" />
                        Attendance History
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          ) : (
            // Desktop Menu
            <li className={menuOpen[0] === 1 ? "js-opened" : ""}>
              <a href="#" onClick={() => toggleParent1(1)} className="mn-has-sub">
                Features <i className="mi-chevron-down" />
              </a>
              <ul className={`mn-sub mn-has-multi ${menuOpen[0] === 1 ? "mobile-sub-active" : ""}`}>



                {/* Column 1 */}
                <li className="mn-sub-multi" style={{ minWidth: '250px', marginLeft: '-30%', display: 'flex', flexDirection: 'column' }}>
                  {/* <span className="mn-group-title mycode ">All Features</span> */}
                  <ul>
                    <li onMouseOver={() => handleMouseOverSolution('Employee')}
                      onMouseEnter={() => setIsHoveredemployee(true)}
                      onMouseLeave={() => setIsHoveredemployee(false)}>
                      <a href="/hrms-software/employee-management-system/">
                        Employee Management&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {isHoveredemployee ?
                          <i className="mi-arrow-right"
                            style={{
                              alignItems: 'right',
                              fontSize: '18px',
                              // display: 'flex',
                              justifyContent: 'flex-end'
                            }} />
                          :
                          ' '
                        }
                      </a>
                    </li>
                    <li onMouseOver={() => handleMouseOverSolution('Attendance')}
                      onMouseEnter={() => setIsHoveredattendance(true)}
                      onMouseLeave={() => setIsHoveredattendance(false)}>
                      <a href="/hrms-software/attendance-management-system/">
                        Attendance Management&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {isHoveredattendance ?
                          <i className="mi-arrow-right"
                            style={{
                              alignItems: 'right',
                              fontSize: '18px',
                              // display: 'flex',
                              justifyContent: 'flex-end'
                            }} />
                          :
                          ' '
                        }
                      </a>
                    </li>
                    <li onMouseOver={() => handleMouseOverSolution('Leave')}
                      onMouseEnter={() => setIsHoveredleave(true)}
                      onMouseLeave={() => setIsHoveredleave(false)}
                    // style={{ minWidth: '280px', marginLeft: '-30%', display: 'flex', flexDirection: 'column' }}
                    >
                      <a href="/hrms-software/leave-management-system"
                      // className="btn hover:btn-grad btn-large btn-round mb-xs-10" data-btn-animate="y"
                      // style={{ minWidth: '250px', display: 'flex', flexDirection: 'column' }}
                      >
                        Leave Management System&nbsp;&nbsp;&nbsp;
                        {isHoveredleave ?
                          <i className="mi-arrow-right"
                            style={{
                              alignItems: 'right',
                              fontSize: '18px',
                              // display: 'flex',
                              justifyContent: 'flex-end'
                            }} />
                          :
                          ' '
                        }
                      </a>
                    </li>
                    <li onMouseOver={() => handleMouseOverSolution('payroll')}
                      onMouseEnter={() => setIsHoveredpayroll(true)}
                      onMouseLeave={() => setIsHoveredpayroll(false)}
                    >
                      <a href="/hrms-software/payroll-management-system/">
                        Payroll Management&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {isHoveredpayroll ?
                          <i className="mi-arrow-right"
                            style={{
                              alignItems: 'right',
                              fontSize: '18px',
                              // display: 'flex',
                              justifyContent: 'flex-end'
                            }} />
                          :
                          ' '
                        }
                      </a>
                    </li>
                    {/* <li onMouseOver={() => handleMouseOverSolution('performance')}>
                      <a href="/">
                        Performance Management
                      </a>
                    </li> */}
                    <li onMouseOver={() => handleMouseOverSolution('recruitment')}
                      onMouseEnter={() => setIsHoveredrecruitment(true)}
                      onMouseLeave={() => setIsHoveredrecruitment(false)}
                    >
                      <a href="/hrms-software/recruitment-management-systems/">
                        Recruitment Management&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {isHoveredrecruitment ?
                          <i className="mi-arrow-right"
                            style={{
                              alignItems: 'right',
                              fontSize: '18px',
                              // display: 'flex',
                              justifyContent: 'flex-end'
                            }} />
                          :
                          ' '
                        }
                      </a>
                    </li>
                    {/* <li onMouseOver={() => handleMouseOverSolution('traininganddeevlopment')}>
                      <a href="/">
                        Training & Development
                      </a>
                    </li> */}
                    {/* <li onMouseOver={() => handleMouseOverSolution('employeeengage')} >
                      <a href="/" >
                        Employee Engagement
                      </a>
                    </li>
                    <li onMouseOver={() => handleMouseOverSolution('compliancepolicy')}>
                      <a href="/">
                        Compliance & Policy Management
                      </a>
                    </li>
                    <li onMouseOver={() => handleMouseOverSolution('analyticsreporting')}>
                      <a href="/">
                        Analytics & Reporting
                      </a>
                    </li> */}
                  </ul>
                </li>

                {/* Column 2 */}
                <li className="mn-sub-multi" style={{ minWidth: '250px', marginLeft: '-30%', display: 'flex', flexDirection: 'column' }}>
                  <ul>
                    {/* <span className="mn-group-title mycode">
                      <a href="/book-demo">
                        Book A Demo 
                      </a>
                    </span> */}
                    {selectedSolution && selectedSolution.subItems.map((subItem, index) => (
                      <li key={index} onMouseOver={() => handleMouseOverSubItem(subItem)}>
                        <a href={subItem.link}>{subItem.title}</a>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </li>
          )}

          {/* my menu */}
          <li
            className={menuOpen[1] == 1 ? "js-opened" : ""}
          >
            <a
              href="#"
              onClick={() => toggleParent2(1)}
              className="mn-has-sub"
            >
              Products <i className="mi-chevron-down" />
            </a>
            {isMobile
              ?
              (
                // Mobile Menu
                <ul
                  className={`mn-sub mn-has-multi ${menuOpen[1] == 1 ? "mobile-sub-active" : ""
                    } `}
                >
                  {/* Column 1 */}
                  <li className="mn-sub-multi"

                    style={
                      !isMobile
                        ? {
                          maxWidth: '150px',
                          marginLeft: '-30%',
                          display: 'flex',
                          flexDirection: 'column'
                        }
                        : {}
                    }
                  >
                    <span className="mn-group-title">All Products</span>
                    <ul>
                      <li onMouseOver={() => handleMouseOver('inventory')}>
                        <a href="/hrms-software"
                          className="btn  btn-round mb-xs-10 hover-effect"
                          style={{
                            transition: 'box-shadow 0.3s ease',
                            borderBottom: '1px solid white',
                            boxShadow: 'none',
                            ':hover': {
                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            },
                            textAlign: 'left'
                          }}
                          onMouseEnter={() => setIsHovered1(true)}
                          onMouseLeave={() => setIsHovered1(false)}
                        >HRMS
                        </a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('financial')} >
                        <a href="/"
                          style={{ borderBottom: '1px solid white' }}
                          className="btn  btn-round mb-xs-10 hover-effect"
                          onMouseEnter={() => setIsHovered2(true)}
                          onMouseLeave={() => setIsHovered2(false)}
                        >CMS</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('vehicle')}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <a href="/"
                          style={{ borderBottom: '1px solid white', color: 'white' }}
                          className="btn  !text-white btn-round mb-xs-10 "
                        >Campaigns
                        </a>

                      </li>
                      <li>
                        <a href="/"
                          className="btn  btn-round mb-xs-10 "
                        >&nbsp;</a>
                      </li>
                    </ul>
                  </li>
                </ul>

              )
              :
              (
                // Desktop Menu
                <ul
                  className={`mn-sub mn-has-multi ${menuOpen[1] == 1 ? "mobile-sub-active" : ""
                    } `}
                >
                  {/* Column 1 */}
                  <li className="mn-sub-multi"

                    style={
                      {
                        maxWidth: '150px',
                        marginLeft: '-30%',
                        display: 'flex',
                        flexDirection: 'column'
                      }
                    }
                  >
                    <span className="mn-group-title">All Products</span>
                    <ul>
                      <li onMouseOver={() => handleMouseOver('inventory')}>
                        <a href="/hrms-software"

                          className="btn btn-round mb-xs-10 hover-effect"
                          style={{
                            transition: 'box-shadow 0.3s ease',
                            borderBottom: '1px solid white',
                            boxShadow: 'none',
                            ':hover': {
                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            },
                            textAlign: 'left'
                          }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >HRMS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {isHovered ?
                            <i className="mi-arrow-right"
                              style={{
                                alignItems: 'right',
                                fontSize: '18px',
                                // display: 'flex',
                                justifyContent: 'flex-end'
                              }} />
                            :
                            ' '
                          }
                        </a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('financial')} className="py-3"
                        onMouseEnter={() => setIsHovered1(true)}
                        onMouseLeave={() => setIsHovered1(false)}
                      >
                        <a href="/"
                          style={{ borderBottom: '1px solid white' }}
                          className="btn btn-round mb-xs-10 hover-effect"
                        >CMS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {isHovered1 ?
                            <i className="mi-arrow-right"
                              style={{
                                alignItems: 'right',
                                fontSize: '18px',
                                // display: 'flex',
                                justifyContent: 'flex-end'
                              }} />
                            :
                            ' '
                          }
                        </a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('vehicle')}
                        onMouseEnter={() => setIsHovered2(true)}
                        onMouseLeave={() => setIsHovered2(false)}
                      >
                        <a href="/"
                          style={{ borderBottom: '1px solid white' }}
                          className="btn !text-white btn-round mb-xs-10 hover-effect"
                        >Campaigns&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                          {isHovered2 ?
                            <i className="mi-arrow-right" style={{ alignItems: 'right', fontSize: '16px' }} />
                            :
                            ' '
                          }
                        </a>

                      </li>
                      <li>
                        <a href="/"
                          className="btn  btn-round mb-xs-10 "
                        >&nbsp;</a>
                      </li>
                    </ul>
                  </li>

                  {/* Column 2 */}
                  <li className="mn-sub-multi"
                    style={{
                      minWidth: '350px',
                      marginLeft: '-30%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                    <span className="mn-group-title">&nbsp;</span>
                    <ul>
                      <li className='!min-w-[350px] !ml-[-30%] flex flex-col '>
                        {/* <img src={selectedSolution ? selectedSolution.image : '/assets/images/img/it_solutions_itsm.webp'}
                      className='pt-5 pb-2 rounded-md w-screen' alt={selectedSolution ? selectedSolution.title : 'Solution'} /> */}

                        <p className="menu-heading" style={{ color: 'var(--color-gray-light-5)' }}>
                          {selectedSolution ? selectedSolution.title : 'All Products'}
                        </p>
                        <p className="sec-text  pb-3 !min-w-fit" style={{ color: 'var(--color-gray-light-5)', textAlign: 'left', padding: "10px" }}>
                          {selectedSolution ? selectedSolution.description : 'Products Project Goals for Accurate Costs and Timely Completion Clarify Project Goals for Accurate Costs and Timely Completion Clarify Project Goals for Accurate Costs and Timely Completion'}
                        </p>
                      </li>
                    </ul>
                  </li>

                  {/* Column 2 */}
                  <li className="mn-sub-multi" style={{
                    minWidth: '350px',
                    marginLeft: '-30%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    {/* <span className="mn-group-title">column 2</span> */}
                    <ul>
                      <li className='!min-w-[350px] !ml-[-30%] flex flex-col '>
                        <img src={selectedSolution ? selectedSolution.image : '/assets/images/img/it_solutions_itsm.webp'}
                          className='pt-5 pb-2 rounded-md w-screen' alt={selectedSolution ? selectedSolution.title : 'Solution'} />

                        {/* <a className="menu-heading text-black ">
                      {selectedSolution ? selectedSolution.title : 'Smart Solutions'}
                    </a>
                    <a className="sec-text text-black pb-3 !min-w-fit">
                      {selectedSolution ? selectedSolution.description : 'Clarify Project Goals for Accurate Costs and Timely Completion'}
                    </a> */}
                      </li>
                    </ul>
                  </li>

                </ul>

              )
            }

          </li>


          {/* Item With Sub */}
          {/* {menuItems.slice(5, 8).map((item, index) => ( */}

          {/* // ))} */}
          {/* End Item With Sub */}

          {/* Solutions only list  */}
          <li
            className={menuOpen[2] == 1 ? "js-opened" : ""}
          >
            <a
              href="#"
              onClick={() => toggleParent3(1)}
              className="mn-has-sub"
            >
              Solutions <i className="mi-chevron-down" />
            </a>
            <ul
              className={`mn-sub mn-has-multi ${menuOpen[2] == 1 ? "mobile-sub-active" : ""
                } `}

              style={
                !isMobile ? {
                  minWidth: '200px',
                  marginLeft: '-30%',
                  // display: 'flex',
                  flexDirection: 'column'
                } : {}
              }
            >
              {/* Column 1 */}
              {/* <li className="mn-sub-multi" >
                <span className="mn-group-title">column 1</span>
                <ul> */}
              <li>
                <a href="/hrms-software/solutions/ca-firm/">
                  CA Firm
                </a>
              </li>
              <li>
                <a href="/hrms-software/solutions/cs-firm/">
                  CS Firm
                </a>
              </li>
              <li>
                <a href="/hrms-software/solutions/cpa-firm/">
                  CPA Firm
                </a>
              </li>
              <li>
                <a href="/hrms-software/solutions/law-firm/">
                  LAW Firm
                </a>
              </li>
              <li>
                <a href="/hrms-software/solutions/bpo-kpo/">
                  BPO/KPO
                </a>
              </li>
              <li>
                <a href="/hrms-software/solutions/real-estate/">
                  Real Estate
                </a>
              </li>

              {/* </ul> */}
              {/* </li> */}
            </ul>
          </li>

          {/* Resources  */}
          <li
            className={menuOpen[3] == 1 ? "js-opened" : ""}
          >
            <a
              // href="#"
              onClick={() => toggleParent4(1)}
              className="mn-has-sub"
            >
              Resources <i className="mi-chevron-down" />
            </a>
            <ul
              className={`mn-sub mn-has-multi ${menuOpen[3] == 1 ? "mobile-sub-active" : ""
                } `}
            >
              <li>
                <a href="/blogs">
                  Blogs
                </a>
              </li>
              <li>
                <a href="/glossary">
                  Glossary
                </a>
              </li>
              <li>
                <a href="/testimonials">
                  Testimonials
                </a>
              </li>
            </ul>
          </li>

          {/* About Us */}
          <li>
            <a href="/about">About Us</a>
          </li>

          {/* Pricing */}
          <li>
            <a href="/pricing">Pricing</a>
          </li>

          {/* Contact Us */}
          <li>
            <a href="/contactus">Contact Us</a>
          </li>

        </ul>
        <ul className="items-end clearlist">
          {/* Languages */}
          {/* <LanguageSelect /> */}
          {/* End Languages */}
          {/* <li>
            <a
              href="/signin"
              target="_blank"
              rel="nofollow noopener"
              className="opacity-1 no-hover"
            >
              <span className="link-hover-anim underline" data-link-animate="y">
                <span className="link-strong link-strong-unhovered">
                  Sign in
                </span>
                <span
                  className="link-strong link-strong-hovered"
                  aria-hidden="true"
                >
                  Sign in
                </span>
              </span>
            </a>
          </li> */}
          <li>
            <a href="/pricing" className="opacity-1  contact-link"
            // onMouseEnter={() => setIsHovered(true)}
            // onMouseLeave={() => setIsHovered(false)}
            >
              <span className="btn btn-mod btn-border-grad btn-small btn-round"
                style={{
                  transition: 'box-shadow 0.3s ease',
                  boxShadow: isHovered ? '0 4px 8px rgba(0, 0, 0, 0.3)' : 'none',
                }}>
                <span data-btn-animate="y">Start 15 Days Trial</span>
              </span>
            </a>
          </li>
        </ul>
      </div>
      {/* End Main Menu */}
    </div >
  );
}
